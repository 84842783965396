export const mockPosts = {
	'AnA2-P15c': {
		name: 'New page',
		path: '/howtomakenachos',
		blocks: [
			'AnA2-P15c-header',
			'AnA2-P15c-section',
		],
		type: 'blog',
		isDraft: false,
		coverImageOrigin: 'unsplash',
		coverImagePath: 'photo-1582169296194-e4d644c48063?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjcyNTgzfQ',
		coverImageAlt: 'selective focus photography of white USB port',
		date: '2023-04-02',
		categories: [],
		minutesToRead: '4',
		meta: {
			title: 'How to make the perfect nachos',
			description: 'Nachos are tasty. But they can be made even better.',
			ogImageOrigin: 'unsplash',
			ogImagePath: 'photo-1582169296194-e4d644c48063?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjcyNTgzfQ',
			ogImageAlt: 'selective focus photography of white USB port',
		},
	},
	xYeRqh3F4: {
		name: 'New page',
		path: '/pullingallnighters',
		blocks: [
			'xYeRqh3F4-header',
			'xYeRqh3F4-section',
		],
		type: 'blog',
		isDraft: false,
		coverImageOrigin: 'unsplash',
		coverImagePath: 'photo-1536746803623-cef87080bfc8?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjcyNTgzfQ',
		coverImageAlt: 'four brown gift boxes on white surface',
		date: '2020-06-01',
		categories: [],
		minutesToRead: '1',
		meta: {
			title: 'Pulling all nighters',
			description: 'Every developer has done this. And will do it again and again and again...',
			ogImageOrigin: 'unsplash',
			ogImagePath: 'photo-1536746803623-cef87080bfc8?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjcyNTgzfQ',
			ogImageAlt: 'four brown gift boxes on white surface',
		},
	},
	xYeRqh3F5: {
		name: 'New page',
		path: '/majestyofmountains',
		blocks: [
			'xYeRqh3F5-header',
			'xYeRqh3F5-section',
		],
		type: 'blog',
		isDraft: false,
		coverImageAlt: 'brown mountain under white sky during daytime',
		coverImagePath: 'https://cdn.zyrosite.com/cdn-builder-placeholders/blog/mountains-in-the-evening.jpeg',
		coverImageOrigin: 'other',
		date: '2023-04-01',
		categories: [],
		minutesToRead: '1',
		meta: {
			title: 'The Majesty of Mountains',
			description: 'Why They Leave Us in Awe',
			ogImagePath: 'https://cdn.zyrosite.com/cdn-builder-placeholders/blog/mountains-in-the-evening.jpeg',
			ogImageOrigin: 'other',
			ogImageAlt: 'brown mountain under white sky during daytime',
		},
	},
	xYeRqh3F6: {
		name: 'New page',
		path: '/pancakes',
		blocks: [
			'xYeRqh3F6-header',
			'xYeRqh3F6-section',
		],
		type: 'blog',
		isDraft: false,
		coverImageAlt: 'sliced fruit on white ceramic plate',
		coverImagePath: 'https://cdn.zyrosite.com/cdn-builder-placeholders/blog/pancakes-with-strawberries.jpeg',
		coverImageOrigin: 'other',
		date: '2023-04-02',
		categories: [],
		minutesToRead: '1',
		meta: {
			title: 'Pancakes: The Perfect Breakfast Comfort Food',
			description: 'Pancakes are a classic breakfast staple that many of us love.',
			ogImagePath: 'https://cdn.zyrosite.com/cdn-builder-placeholders/blog/pancakes-with-strawberries.jpeg',
			ogImageOrigin: 'other',
			ogImageAlt: 'sliced fruit on white ceramic plate',
		},
	},
};
